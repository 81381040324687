<script>
export default {
  name: "FormPublicationMixins",
  data: (_this) => ({
    loading: 0,
    selected: 0,
    showSaveMultiple: false,
    fields: [
      "channel_subscription_id",
      "channel_id",
      "creative_id",
      "channel_params",
      "price",
      "type",
      "method",
      "content",
      "params",
    ],
    // database values
    loaded_values: undefined,
    // default values
    default_values: {
      channel_subscription_id: undefined,
      channel_id: undefined,
      creative_id: undefined,
      channel_params: {},
      price: 0,
      type: undefined,
      method: undefined,
      content: {},
      params: {},
    },
    // current values
    values: {
      channel_subscription_id: undefined,
      channel_id: undefined,
      creative_id: undefined,
      channel_params: {},
      price: 0,
      type: undefined,
      method: undefined,
      content: {},
      params: {},
    },
  }),
  props: {
    ids: {
      type: [Array, Boolean],
      default: () => [],
    },
    props: {
      type: [Object, Boolean],
      default: () => ({}),
    },
  },
  watch: {
    ids(n, o) {
      if (n && n.length > 0) {
        if (n[this.selected].id !== this.id) {
          this.selected = 0;
          this.loadItem();
        }
      }
    },
  },
  computed: {
    jobStatusReport() {
      if (this.loaded_values && this.loaded_values.job.status_report) {
        return this.loaded_values.job.status_report[
          this.loaded_values.channel.controller_name
        ];
      }
      return true;
    },
    canPrev() {
      return this.selected > 0 && this.ids.length > 0;
    },
    canNext() {
      return this.selected < this.ids.length - 1 && this.ids.length > 0;
    },
    id() {
      return this.loaded_values ? this.loaded_values.id : null;
    },
  },
  created() {
    if (this.ids.length > 0) {
      this.loadItem();
    } else {
      this.resetItem();
    }
  },
  methods: {
    // apply changes from another data (child component)
    handleInput(values) {
      values = this.$clone(values);
      for (const field of this.fields) {
        if (typeof values[field] !== "undefined") {
          if (
            values[field] instanceof Object &&
            !(values[field] instanceof Array)
          ) {
            this.values[field] = Object.assign(
              {},
              this.values[field],
              values[field]
            );
          } else {
            this.values[field] = values[field];
          }
        }
      }
    },

    // init and reset values
    resetItem() {
      const values = this.$clone(
        this.id ? this.loaded_values : this.default_values
      );
      for (const field of this.fields) {
        if (typeof values[field] !== "undefined")
          this.values[field] = values[field];
      }
    },

    // load prev item on the fly
    prevItem() {
      if (this.canPrev) {
        this.selected--;
        return this.loadItem();
      }
    },

    // load next item on the fly
    nextItem() {
      if (this.canNext) {
        this.selected++;
        return this.loadItem();
      }
    },

    // load item
    loadItem() {
      this.loading = true;
      const query = {
        where: {
          id: this.ids[this.selected],
          channel_subscription: true,
          channel: true,
          job:true,
          campaign: true,
          creative: true
        },
        options: {
          limit: 1,
        },
      };
      return this.$services.api_programmatic.publication
        .search(query)
        .then((response) => {
          if (response.data && response.data[0]) {
            this.loaded_values = this.$clone(response.data[0]);
          }
          return response;
        })
        .finally(() => {
          this.resetItem();
          this.loading = false;
        });
    },

    // save item
    saveItem(multiple = false) {

      if (this.ids.length > 1 && (typeof multiple !== "boolean" || !multiple))
        return (this.showSaveMultiple = true);
      this.showSaveMultiple = false;
      this.loading = true;
      if (!this.id) {
        const values = {};
        for (const field of this.fields) {
          if (typeof this.values[field] !== "undefined")
            values[field] = this.values[field];
        }
        return this.$services.api_programmatic.publication
          .create({ values })
          .then((response) => {
            if (response.data && response.data.filter((v) => v).length > 0) {
              this.$emit("create", {
                id: response.data.filter((v) => v)[0].id,
              });
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        const values = this.$compare(
          this.loaded_values,
          this.values,
          this.fields
        );
        if (Object.keys(values).length > 0) {
          const ids = multiple && this.ids.length > 1 ? this.ids : this.id;
          return this.$services.api_programmatic.publication
            .update({ values, where: { id: ids }, options: {limit:ids.length} })
            .then((response) => {
              if (response.data) {
                this.$emit("update", ids);
              }
            })
            .catch(e => {
              this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      }
    },

    // remove item
    removeItem(multiple = true) {
      const ids = multiple && this.ids.length > 1 ? this.ids : this.id;
      if (ids) {
        this.loading = true;
        return this.$services.api_programmatic.publication
          .remove({ where: { id: ids } })
          .then((response) => {
            if (response.data) {
              this.$emit("remove", ids);
            }
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        console.warning("You cannot remove element without its id.");
      }
    },

    // refresh
    refreshItem(multiple = true) {
      const ids = multiple && this.ids.length > 1 ? this.ids : this.id;
      if (ids) {
        this.loading = true;
        return this.$services.api_programmatic.publication
          .dispatch({ where: { id: ids } })
          .then((response) => {
            if (response.data) {
              this.$emit("update", ids)
            }
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },


    cancelRefresh (multiple = true) {
      const ids = multiple && this.ids.length > 1 ? this.ids : this.id;
      if (ids) {
        this.loading = true
        return this.$services.api_programmatic.publication.update({where: {id: ids}, values: {need_refresh: null, status_type:null, status_code: null, status_message: null}})
          .then(response => {
            this.$emit('update', this.id)
          })
          .catch(e => {
            this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 3000 })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },


  },
};
</script>
